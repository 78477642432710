import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../Firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import CottageTile from '../Components/CottageTile';
import { CottageInfo } from '../Models/CottageInfo';
import { getCottages } from '../Services/CottageTripService';
import { Grid, CircularProgress, GridSize, Box, Drawer, Dialog, DialogTitle, DialogContent, DialogActions, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import useScreenSize, { ScreenSize } from '../Hooks/useCheckMobile';
import './styles/Cottages.css'
import InviteInboxTile from '../Components/InviteInboxTile';
import { acceptCottageInvite, declineCottageInvite, getInvitedCottages } from '../Services/UserService';
import CreateCottage from '../Components/CreateCottage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Cottages() {

    // State

    const [loggedInUserId, setLoggedInUserId] = useState<string>('');
    const [cottages, setCottages] = useState<CottageInfo[]>([]);
    const [cottagesLoading, setCottagesLoading] = useState<boolean>(true)
    const [gridSpace, setGridSpace] = useState<GridSize>(3)
    const [invites, setInvites] = useState<CottageInfo[]>([]);
    const [openInvitesInbox, setOpenInvitesInbox] = useState<boolean>(false);
    const [isAcceptingOrDecliningInvite, setIsAcceptingOrDecliningInvite] = useState<boolean>(false);

    const screenSize = useScreenSize()

    //Navigation logic
    const navigate = useNavigate()
    const routeToCottage = (id: string) => {
        navigate('/cottage/' + id + '/information');
    };
    const routeToHomePage = useCallback(() => {
        navigate('/');
    }, [navigate]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setLoggedInUserId(user.uid);
                getCottages(user.uid)
                .then((cottages) => {
                    setCottages(cottages);
                    setCottagesLoading(false);
                });
                getInvitedCottages(user.uid)
                .then((invitedCottageIds) => {
                    setInvites(invitedCottageIds);
                })
            } else {
                routeToHomePage();
            }
        });
    }, [routeToHomePage]);

    useEffect(() => {

        const SetGridLayout = () => {
    
            if (screenSize <= ScreenSize.xs)
                setGridSpace(12)
            else if (screenSize <= ScreenSize.sm)
                setGridSpace(6)
            else if (screenSize <= ScreenSize.md)
                setGridSpace(4)
            else
                setGridSpace(3)
    
        }

        SetGridLayout()

    }, [screenSize])

    //Modal logic
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //Modal styling
    const style = {
        bgcolor: '#FFFBF0',
        border: '2px solid #000',
        boxShadow: 24
      };

      //Close button
      const closeCreateCottageForm = () => {
        handleClose();
      }

      const reloadCottagePage = () => {
        window.location.reload();
      }

    // Invites Inbox Logic

    const didClickInviteInbox = () => {
        setOpenInvitesInbox(true);
    }

    const handleClickCloseInviteInbox = () => {
        setOpenInvitesInbox(false);
    }

    const inviteDeclined = (cottageId: string, index: number) => {
        setIsAcceptingOrDecliningInvite(true);
        declineCottageInvite(cottageId, loggedInUserId)
        .then(() => {
            setIsAcceptingOrDecliningInvite(false);
            setOpenInvitesInbox(false);
            removeInviteFromInbox(cottageId);
        })
        .catch(() => {
            setIsAcceptingOrDecliningInvite(false);
        })
    }

    const inviteAccepted = (cottageId: string, index: number) => {
        setIsAcceptingOrDecliningInvite(true);
        acceptCottageInvite(cottageId, loggedInUserId)
        .then(() => {
            setIsAcceptingOrDecliningInvite(false);
            setCottagesLoading(true);
            setOpenInvitesInbox(false);
            setCottages([]);
            setInvites([]);
            getCottages(loggedInUserId)
            .then((cottages) => {
                setCottages(cottages);
                setCottagesLoading(false);
            });
            removeInviteFromInbox(cottageId);
        })
        .catch(() => {
            setIsAcceptingOrDecliningInvite(false);
        })
    }

    const removeInviteFromInbox = (cottageId: string) => {
        setInvites(
            invites.filter(invite =>
                invite.cottageID !== cottageId
            )
        );
    }

    return (
        <div className='NewCottage-container'>
            <div style={{ height: '25%', paddingBottom: '2%' }}>
                <button className='NewCottage-button' style={{ backgroundSize: '360px, 100%', color: 'black' }} onClick={handleOpen}>
                    Create a New Cottage
                </button>
            </div>
            <Drawer anchor='bottom' open={open} onClose={handleClose} sx={{zIndex:'1000000000000'}}>
                <Box sx={style}>
                    <CreateCottage closeButtonClicked={closeCreateCottageForm} reloadCottagePage={reloadCottagePage}/>
                </Box>
            </Drawer>
            

            {
                invites.length !== 0 &&
                <div onClick={didClickInviteInbox} style={{ width: '25%', paddingBottom: '40px' }}>
                    <InviteInboxTile/>
                </div>
            }

            <div style={{ flexGrow: 1 }}>
                <h1 style={{ fontFamily: 'monospace' }}>Your Cottages</h1>
                {
                    !cottagesLoading && cottages.length === 0 ? 
                        <div style={{ fontFamily: 'monospace' }}>
                            No cottages found...
                        </div>
                    : null
                }
                <Grid container style={{ paddingBottom: 25 }}>
                {
                    !cottagesLoading || cottages.length > 0 ?
                    cottages.map((item) => {
                        return (
                            <Grid key={item.cottageID} item xs={gridSpace} style={{ cursor: 'pointer', paddingRight: 20}}>
                                <div onClick={()=> routeToCottage(item.cottageID)} style={{ height: '100%' }}>
                                    <CottageTile name={item.cottageName} address={item.address} startDate={item.startDate} />
                                </div>
                            </Grid>
                        )
                    })
                    :
                    <div>
                        <CircularProgress color='success' />
                    </div>
                }
                </Grid>
            </div>
            <div>
                <Dialog
                    open={openInvitesInbox}
                    onClose={handleClickCloseInviteInbox}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{ zIndex:'10000000000000000000' }}
                    fullWidth={true}
                >
                    <DialogTitle 
                        id="alert-dialog-title"
                        sx={{ backgroundColor: '#FFFBF0' }}
                    >
                        {"Cottage Invites"}
                    </DialogTitle>
                    <DialogContent
                        sx={{ backgroundColor: '#FFFBF0' }}
                    >
                        { isAcceptingOrDecliningInvite ?
                        <div>
                            <CircularProgress color='success' />
                        </div>
                        :
                        invites.map((cottage, index) => (
                            <div className='Invites-accordion-container'>
                                <Accordion sx={{ backgroundColor: '#D5E7B8' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <div className='Invite-inbox-cell-summary-container'>
                                            <div className='Invite-inbox-cell-text-label'>
                                                {cottage.cottageName}
                                            </div>
                                            <div className='Invite-inbox-cell-text-label'>
                                                {cottage.cottageOrganizer.name}
                                            </div>
                                            <div className='Invite-inbox-cell-text-label'>
                                                {cottage.startDate.toDateString()}
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='Invite-buttons-container'>
                                            <div className='Invite-button-container'>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: '#7d1e23',
                                                        '&:hover': {
                                                            backgroundColor: '#571518'
                                                        },
                                                        width: '100%'
                                                    }}
                                                    onClick={() => inviteDeclined(cottage.cottageID, index)}>
                                                    Decline
                                                </Button>
                                            </div>
                                            <div className='Invite-button-container'>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        backgroundColor: '#1A3B0E',
                                                        '&:hover': {
                                                            backgroundColor: '#102408'
                                                        },
                                                        width: '100%'
                                                    }}
                                                    onClick={() => inviteAccepted(cottage.cottageID, index)}>
                                                    Accept
                                                </Button>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        ))
                        }
                    </DialogContent>
                    <DialogActions
                        sx={{ backgroundColor: '#FFFBF0' }}
                    >
                        <Button 
                            onClick={handleClickCloseInviteInbox}
                            sx={{ color: '#1A3B0E' }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}

export default Cottages;
