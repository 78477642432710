export const SelectFieldInput = {
    background: '#D5E7B8',
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: '#1A3B0E',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1A3B0E',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1A3B0E',
      },
    }

