import './styles/Home.css';
import About from './About';
import { Box, Button, Divider, Modal } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import OnboardingTour from '../Components/OnboardingTour';

interface HomePageProps {
    title: string;
    includeTourAndSignInLinks: boolean;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '70vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    zIndex: '1000000000000000000',
    backgroundColor: '#FFFBF0'
  };

function Home( { title, includeTourAndSignInLinks } : HomePageProps ) {

    //State

    const [isTourOpen, setIsTourOpen] = useState<boolean>(false);

    //Navigation logic
    const navigate = useNavigate();

    const signInButtonClicked = () => {
        navigate('/login');
        window.scrollTo(0, 0);
    };

    //Functions

    const openTourModal = () => {
        setIsTourOpen(true);
    };

    const closeTourModal = () => {
        setIsTourOpen(false);
    };

    return (
        <div className="Homepage-container">
            
            <h1 className="Homepage-title">
                {title}
            </h1>
            { includeTourAndSignInLinks &&
                <div className='Homepage-onboard-section'>
                    <Button
                        variant="contained"
                        sx={{
                            margin: '10px',
                            backgroundColor: '#1A3B0E',
                            '&:hover': {
                                backgroundColor: '#102408'
                            }
                        }}
                        onClick={openTourModal}
                    >
                        Take a Tour
                    </Button>
                    <div style={{ textAlign: 'center', alignItems: 'center' }}>
                        or
                    </div>
                    <Button
                        variant="contained"
                        sx={{
                            margin: '10px',
                            backgroundColor: '#1A3B0E',
                            '&:hover': {
                                backgroundColor: '#102408'
                            }
                        }}
                        onClick={signInButtonClicked}
                    >
                        Sign In Or Create Account
                    </Button>
                </div>
            }
            { includeTourAndSignInLinks &&
                <Divider style={{ width:'90%', marginBottom: '20px' }}/>
            }
            <div className='Homepage-abount-section-container'>
                <About/>
            </div>

            <Modal
                open={isTourOpen}
                onClose={closeTourModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    zIndex: '10000000000000000'
                }}
            >
                <Box sx={style}>
                    <div style={{ height: '100%' }}>
                        <OnboardingTour/>
                    </div>
                </Box>
            </Modal>

        </div>
    );

}

export default Home;