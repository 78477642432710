import { Delete, FilterList } from "@mui/icons-material";
import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";

interface EnhancedTableToolbarProps {
    numSelected: number;
    deleteItemsButtonPressed: () => void;
    canEditList: boolean;
  }

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {

    //props
    const { numSelected, deleteItemsButtonPressed, canEditList } = props;

    return (
      <Toolbar
        sx={{ backgroundColor: '#D5E7B8' }}
      >
        {numSelected > 0 && canEditList ? 
          (
            <Typography
              sx={{ flex: '1 1 100%', fontSize: '30px', color: '#1A3B0E' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} {numSelected > 1 ? 'items' : 'item'} selected
            </Typography>
          ) 
          : 
          (
            <Typography
              sx={{ flex: '1 1 100%', fontSize: '30px', fontWeight: 'bold', color: '#1A3B0E' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Items
            </Typography>
          )
        }
        { numSelected > 0 && canEditList && 
          (
            <Tooltip title="Delete">
              <IconButton onClick={ deleteItemsButtonPressed }>
                <Delete />
              </IconButton>
            </Tooltip>
          ) 
        }
        { numSelected <= 0 && canEditList && 
          (
            <Tooltip title="Filter list">
              <IconButton>
                <FilterList />
              </IconButton>
            </Tooltip>
          )
        }
      </Toolbar>
    );
  }

  export default EnhancedTableToolbar;