import { Button, CircularProgress, Grid, IconButton, Switch, TextField, Tooltip, styled } from '@mui/material';
import './styles/CreateCottage.css'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { Close, Info } from '@mui/icons-material';
import { FormEvent, useState } from 'react';
import { createCottage } from '../Services/CottageTripService';
import { TextFieldInput } from '../Shared/styles/TextField';
import dayjs, { Dayjs } from 'dayjs';

const CreateCottageButton = styled(Button)(({ theme, color = 'primary' }) => ({
    color: '#FFFBF0',
    backgroundColor: '#103803',
    fontWeight: 'bold',
    fontFamily: 'monospace',
    ':hover': {
      color: '#FFFBF0',
      backgroundColor: '#103803',
    },
  }));

interface createCottageFormProps{
    closeButtonClicked: () => void
    reloadCottagePage: () => void
}

function CreateCottage ({ closeButtonClicked = () => {}, reloadCottagePage = () => {} } : createCottageFormProps) {

    //Submitting state logic
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    //Create cottage button
    const createCottagePressed = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const cottageName: string = formData.get('cottageName') as string;
        const cottageAddress: string = formData.get('cottageAddress') as string;
        const startDate = new Date(formData.get('startDate')?.toString() ?? "");
        const endDate = new Date(formData.get('endDate')?.toString() ?? "");

        if (startDate >= endDate) {
            alert("End date cannot be before start date");
            return;
        }

        const collaborativePostsValue = formData.get('collaborativePosts');
        var collaborativePosts: boolean = true;
        if (!collaborativePostsValue) {
            collaborativePosts = false;
        }

        const collaborativeGroceriesValue = formData.get('collaborativeGroceries');
        var collaborativeGroceries: boolean = true;
        if (!collaborativeGroceriesValue) {
            collaborativeGroceries = false;
        }
    
        setIsSubmitting(true);
        createCottage(cottageName, cottageAddress, startDate, endDate, collaborativeGroceries, collaborativePosts)
        .then( (info) => {
                reloadCottagePage();
            }
        )
    }

    //Date validation
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs(new Date()));

    return (
        <div className='Createcottage-form-container'>
            <div className='Closebutton-container'>   
                <IconButton onClick={closeButtonClicked}>
                    <Close />
                </IconButton>
            </div>
            <form onSubmit={createCottagePressed}>
                <Grid container direction={"column"} spacing={5}>
                    <Grid item className='Createcottage-form-title'>
                        <p>Create a New Cottage</p>
                    </Grid>
                    <Grid item>
                        <TextField 
                            sx={TextFieldInput} 
                            inputProps={{autoComplete: 'off'}} 
                            InputLabelProps={{ style: { color: '#73925B' }}}
                            className='CreateCottage-input' 
                            label="Name"
                            name='cottageName'
                            required
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            sx={TextFieldInput} 
                            inputProps={{autoComplete: 'off'}}
                            InputLabelProps={{ style: { color: '#73925B' }}} 
                            className='CreateCottage-input' 
                            label="Address"
                            name='cottageAddress'
                            required
                        />
                    </Grid>
                    <Grid item>
                        <div className='Createcottage-form-date-label'>
                            Start Date
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker 
                                sx={TextFieldInput}
                                defaultValue={dayjs(new Date())}
                                name='startDate'
                                onChange={setStartDate}
                                slotProps={{
                                    popper: {
                                        disablePortal: true
                                    },
                                    dialog: {
                                        style: { zIndex: '10000000000000000' }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item >
                        <div className='Createcottage-form-date-label'>
                            End Date
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker 
                                sx={TextFieldInput}
                                defaultValue={dayjs(new Date())}
                                name='endDate'
                                minDate={startDate!}
                                slotProps={{
                                    popper: {
                                        disablePortal: true,
                                    },
                                    dialog: {
                                        style: { zIndex: '10000000000000000' }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item className='Createcottage-form-toggle-label'>
                        Collaborative Posts
                        <Switch name='collaborativePosts' defaultChecked={true} />
                        <Tooltip 
                            title="When collaborative posts are turned on, anyone in the cottage can create a post for everyone to see."
                            enterTouchDelay={0}  
                            PopperProps={{
                                style: {
                                    zIndex: '10000000000000000'
                                }
                            }}  
                        >
                            <IconButton>
                                <Info/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item className='Createcottage-form-toggle-label'>
                        Collaborative List Making
                        <Switch name='collaborativeGroceries' defaultChecked={true} />
                        <Tooltip 
                            title="When collaborative list making is turned on, anyone can create and add grocery items to their own list. When this settings is turned off, only the trip admin can create and assign groceries to lists."
                            enterTouchDelay={0}
                            PopperProps={{
                                style: {
                                    zIndex: '10000000000000000'
                                }
                            }}
                        >
                            <IconButton>
                                <Info/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        {
                            isSubmitting ?
                            <CircularProgress color='success' />
                            :
                            <CreateCottageButton 
                                type='submit'
                                disabled={isSubmitting!}
                            >
                                Create Cottage
                            </CreateCottageButton>
                        }
                    </Grid>
                </Grid>
            </form>
        </div>

    )

}

export default CreateCottage;