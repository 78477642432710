import './styles/FaqPage.css'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FaqPage() {
    return (
        <div className='Faqpage-container'>
            <div className='Faqpage-title'>
                FAQ
            </div>
            <div className='Faqpage-no-invites-faq'>
                    <Accordion sx={{ backgroundColor: '#D5E7B8' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className='Faqpage-accordion-summary-container'>
                                <Typography align="center" sx={{ width: '100%', fontWeight: 'bold' }}>"I'm not receiving invites that my friends are sending to me."</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                Cottage Buddy invites are sent through email addresses. If you chose to use Apple sign-in and selected the 'Hide my email' option, Apple creates a random email address for you. You can see this email in the profile section and share it with your friends to send you invites in the future.
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
        </div>
    );
}

export default FaqPage;