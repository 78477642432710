export const TextFieldInput = {
    '& label.Mui-focused': {
      color: '#1A3B0E',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#1A3B0E',
    },
    '& .MuiInputLabel-root': {
      color: '#1A3B0E'
    },
    '& .MuiOutlinedInput-root': {
      color: '#1A3B0E',
      width: 300,
      backgroundColor: '#D5E7B8',
      '& fieldset': {
        borderColor: '#1A3B0E',
      },
      '&:hover fieldset': {
        borderColor: '#1A3B0E',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1A3B0E',
      },
    }
}
