import './styles/CottageInfoTab.css'
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Switch, TextField, Tooltip } from "@mui/material";
import { CottageInfo } from "../Models/CottageInfo";
import { TextFieldInput } from '../Shared/styles/TextField';
import { useCallback, useEffect, useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { Info } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

interface cottageInfoTabProps {
    info: CottageInfo | null;
    loggedInUserId: string;
    editCottageInfo: (info: CottageInfo) => void;
    deleteCottage: () => Promise<void>;
    leaveCottage: () => Promise<null>;
}

const CottageInfoTab = ({ info, loggedInUserId, editCottageInfo, deleteCottage, leaveCottage } : cottageInfoTabProps ) => {

    //State
    var cottageInfoToDisplay: CottageInfo | null = info;
    var userId: string = loggedInUserId;

    //functions
    const setInputValues = useCallback(() => {
        setTripName(cottageInfoToDisplay?.cottageName ?? "");
        setOrganizer(cottageInfoToDisplay?.cottageOrganizer.name ?? "");
        setAddress(cottageInfoToDisplay?.address ?? "");
        setStartDate(dayjs(cottageInfoToDisplay?.startDate));
        setEndDate(dayjs(cottageInfoToDisplay?.endDate));
        setCollaborariveLists(cottageInfoToDisplay?.isGroceriesCollaborative ?? false);
        setCollaborarivePosts(cottageInfoToDisplay?.isPostsCollaborative ?? false);
    }, [cottageInfoToDisplay]);

    useEffect(() => {
        setInputValues();
    },[cottageInfoToDisplay, userId, setInputValues])
 
    const validateInputs = (): Boolean => {
        if (tripName === '' || address === '') {
            return false;
        }
        
        if(startDate == null || endDate == null) {
            return false;
        }

        if (startDate >= endDate) {
            return false;
        }

        return true;
    };

    //state variables
    const [disabled, setDisabled] = useState<boolean>(true);
    const [tripName, setTripName] = useState<string>('');
    const [organizer, setOrganizer] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);
    const [collaborariveLists, setCollaborariveLists] = useState<boolean>(false);
    const [collaborarivePosts, setCollaborarivePosts] = useState<boolean>(false);
    const [openDeleteCottageDialog, setOpenDeleteCottageDialog] = useState<boolean>(false);
    const [isDeletingCottage, setIsDeletingCottage] = useState<boolean>(false);
    const [openLeaveCottageDialog, setOpenLeaveCottageDialog] = useState<boolean>(false);

    //Navigation logic
    const navigate = useNavigate()
    const routeToCottagesPage = () => {
        navigate('/');
    }

    //button functions
    const didClickEdit = () => {
        setDisabled(false);
    };

    const didClickSave = () => {
        const isInputsValid: Boolean = validateInputs();

        if (isInputsValid) {
            setDisabled(true);

            if (info) {
                editCottageInfo(new CottageInfo(info.cottageID, 
                                                tripName, 
                                                info?.cottageOrganizer, 
                                                new Date(startDate?.toString() ?? ""), 
                                                new Date(endDate?.toString() ?? ""), 
                                                collaborariveLists, 
                                                collaborarivePosts, 
                                                address));
            }
            else {
                setDisabled(false);
            }
        }
        else {
            setDisabled(false);
        }
    };

    const didClickCancel = () => {
        setInputValues();
        setDisabled(true);
    };

    const didTogglePosts = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCollaborarivePosts(event.target.checked);
    };

    const didToggleLists = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCollaborariveLists(event.target.checked);
    };

    const didClickDeleteCottage = () => {
        setOpenDeleteCottageDialog(true);
    };

    const handleConfirmDeleteCottage = () => {
        setIsDeletingCottage(true);
        deleteCottage()
        .then(() => {
            routeToCottagesPage();
        })
    };

    const handleCancelDeleteCottage = () => {
        setOpenDeleteCottageDialog(false);
    };

    const didClickLeaveCottage = () => {
        setOpenLeaveCottageDialog(true);
    };

    const handleConfirmLeaveCottage = () => {
        setIsDeletingCottage(true);
        leaveCottage()
        .then(() => {
            routeToCottagesPage();
        })
    };

    const handleCancelLeaveCottage = () => {
        setOpenLeaveCottageDialog(false);
    };

    return (
        <div className='Cottageinfotab-container'>
            {
                cottageInfoToDisplay ?

                <div>
                    <Grid container direction={"column"} spacing={5}>
                        <Grid item className='Createcottage-form-title'>
                            <p>Trip Information</p>
                        </Grid>
                        <Grid item>
                            <TextField 
                                sx={TextFieldInput} 
                                inputProps={{autoComplete: 'off'}} 
                                InputLabelProps={{ shrink: tripName !== '' ? true : false, style: { fontFamily: 'monospace', color: '#73925B' }}}
                                label="Trip Name"
                                name='tripName'
                                value={tripName}
                                onChange={(e) => setTripName(e.target.value)}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item>
                            <TextField 
                                sx={TextFieldInput} 
                                inputProps={{autoComplete: 'off'}} 
                                InputLabelProps={{ shrink: organizer !== '' ? true : false, style: { fontFamily: 'monospace', color: '#73925B' }}}
                                label="Organizer"
                                name='tripOrganizer'
                                value={organizer}
                                onChange={(e) => setOrganizer(e.target.value)}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item>
                            <TextField 
                                sx={TextFieldInput} 
                                inputProps={{autoComplete: 'off'}} 
                                InputLabelProps={{ shrink: address !== '' ? true : false, style: { fontFamily: 'monospace', color: '#73925B' }}}
                                label="Address"
                                name='tripAddress'
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker 
                                    sx={TextFieldInput}
                                    defaultValue={dayjs(new Date())}
                                    label="Start Date"
                                    name='startDate'
                                    value={startDate}
                                    onChange={setStartDate}
                                    disabled={disabled}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker 
                                    sx={TextFieldInput}
                                    defaultValue={dayjs(new Date())}
                                    label="End Date"
                                    name='endDate'
                                    value={endDate}
                                    onChange={setEndDate}
                                    disabled={disabled}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item>
                            Collaborative Posts
                            <Switch name='collaborativePosts' onChange={didTogglePosts} checked={collaborarivePosts} disabled={disabled} />
                            <Tooltip 
                                title="When collaborative posts are turned on, anyone in the cottage can create a post for everyone to see."
                                enterTouchDelay={0}    
                            >
                                <IconButton>
                                    <Info/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            Collaborative List Making
                            <Switch name='collaborativeGroceries' onChange={didToggleLists} checked={collaborariveLists} disabled={disabled} />
                            <Tooltip 
                                title="When collaborative list making is turned on, anyone can create and add grocery items to their own list. When this settings is turned off, only the trip admin can create and assign groceries to lists."
                                enterTouchDelay={0}
                            >
                                <IconButton>
                                    <Info/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        { cottageInfoToDisplay.cottageOrganizer.firebaseUserID === userId ?
                            <Grid item>
                                { disabled ?
                                    <div style={{ paddingBottom: 5 }}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                width: 300,
                                                backgroundColor: '#1A3B0E',
                                                '&:hover': {
                                                    backgroundColor: '#102408'
                                                }
                                            }}
                                            onClick={didClickEdit}>
                                            Edit
                                        </Button>
                                    </div>
                                    :
                                    <div>
                                        <div>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    width: 300,
                                                    backgroundColor: '#1A3B0E',
                                                    '&:hover': {
                                                        backgroundColor: '#102408'
                                                    }
                                                }}
                                                onClick={didClickSave}>
                                                Save
                                            </Button>
                                        </div>
                                        <div style={{ padding: 5 }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    width: 300,
                                                    backgroundColor: '#7d1e23',
                                                    '&:hover': {
                                                        backgroundColor: '#571518'
                                                    }
                                                }}
                                                onClick={didClickCancel}>
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                }
                            </Grid>
                            :
                            <Grid item>
                                <div style={{ paddingBottom: 5 }}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                width: 300,
                                                backgroundColor: '#1A3B0E',
                                                '&:hover': {
                                                    backgroundColor: '#102408'
                                                }
                                            }}
                                            onClick={didClickLeaveCottage}>
                                            Leave Cottage
                                        </Button>
                                    </div>
                            </Grid>
                        }
                        { cottageInfoToDisplay.cottageOrganizer.firebaseUserID === userId &&
                            <Grid item>
                                <div style={{ width: 350, textAlign: 'center', margin: '0 auto' }}>
                                    <Accordion sx={{ backgroundColor: '#D5E7B8' }}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                <DeleteIcon />
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ fontFamily: 'monospace' }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: '#7d1e23',
                                                    '&:hover': {
                                                        backgroundColor: '#571518'
                                                    }, 
                                                    display: 'inline-block', 
                                                    margin: '0 auto',
                                                    width: 300
                                                }}
                                                onClick={didClickDeleteCottage}
                                            >
                                                Delete Cottage
                                            </Button>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </Grid>
                        }
                    </Grid>
                    <Dialog
                        open={openDeleteCottageDialog}
                        onClose={handleCancelDeleteCottage}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{ zIndex:'10000000000000000000' }}
                        fullWidth={true}
                    >
                        <DialogTitle 
                            id="alert-dialog-title"
                        >
                            {"Delete Cottage"}
                        </DialogTitle>
                        <DialogContent
                        >
                            Are you sure you'd like to delete this cottage? This action cannot be undone.
                            { isDeletingCottage &&
                                <CircularProgress color='success' />
                            }
                        </DialogContent>
                            <DialogActions>
                            <Button 
                                onClick={handleCancelDeleteCottage}
                                sx={{ color: '#1A3B0E' }}
                                disabled={isDeletingCottage}
                            >
                                Cancel
                            </Button>
                            <Button 
                                onClick={handleConfirmDeleteCottage}
                                sx={{ color: '#1A3B0E' }}
                                disabled={isDeletingCottage}
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openLeaveCottageDialog}
                        onClose={handleCancelLeaveCottage}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{ zIndex:'10000000000000000000' }}
                        fullWidth={true}
                    >
                        <DialogTitle 
                            id="alert-dialog-title"
                        >
                            {"Delete Cottage"}
                        </DialogTitle>
                        <DialogContent
                        >
                            Are you sure you'd like to leave this cottage? This action cannot be undone.
                            { isDeletingCottage &&
                                <CircularProgress color='success' />
                            }
                        </DialogContent>
                            <DialogActions>
                            <Button 
                                onClick={handleCancelLeaveCottage}
                                sx={{ color: '#1A3B0E' }}
                                disabled={isDeletingCottage}
                            >
                                Cancel
                            </Button>
                            <Button 
                                onClick={handleConfirmLeaveCottage}
                                sx={{ color: '#1A3B0E' }}
                                disabled={isDeletingCottage}
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                :
                <div>
                    <CircularProgress color='success' />
                </div>
            }
        </div>
    )

}

export default CottageInfoTab;