import { Button, Divider } from '@mui/material';
import './styles/SocialsPage.css'
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';

function SocialsPage() {

    const instagramButtonPressed = () => {
        window.open("https://www.instagram.com/cottagebuddyapp", '_blank');
    };

    const twitterButtonPressed = () => {
        window.open("https://x.com/CottageBuddy", '_blank')
    };

    return (
        <div className='Socialspage-container'>
            <div className='Socialspage-title'>
                Socials
            </div>
            <div>
                Connect with us on our social media channels!
            </div>
            <Divider sx={{ padding:'20px', width:'90%', display:'inline-block' }}/>
            <Button
                onClick={instagramButtonPressed}
                sx={{
                    color: '#103803',
                    borderRadius: '500px',
                    backgroundColor: '#D5E7B8',
                    '&:hover': {
                        color: '#FFFBF0',
                        backgroundColor: '#102408'
                    },
                    marginBottom: '10px',
                    marginTop: '20px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '300px'
                }}
            >
                <div style={{ width: '75%' }}>
                    Instagram
                </div>
                <div style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <InstagramIcon/>
                </div>
            </Button>
            <Button
                onClick={twitterButtonPressed}
                sx={{
                    color: '#103803',
                    borderRadius: '500px',
                    backgroundColor: '#D5E7B8',
                    '&:hover': {
                        color: '#FFFBF0',
                        backgroundColor: '#102408'
                    },
                    marginBottom: '10px',
                    marginTop: '10px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    width: '300px'
                }}
            >
                <div style={{ width: '75%' }}>
                    Twitter/X
                </div>
                <div style={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <TwitterIcon/>
                </div>
            </Button>
        </div>
    );
}

export default SocialsPage;